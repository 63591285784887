import { PropsWithChildren } from "react";
import { clsx } from "clsx";

interface Props {
  className?: string;
}

export default function TopLevelFormError(props: PropsWithChildren<Props>) {
  return (
    <p data-testid="top-level-form-error" className={clsx("mb-6 text-center text-xs font-medium text-red-600", props.className)}>{props.children}</p>);
}
